.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.border {
  border: 2px solid #ffffff !important;
}

.cursor-pointer {
  cursor: pointer;
}
.black-bg {
  background-color: #020002;
}
.white-bg {
  background-color: #ffffff;
}
.white-text {
  color: #ffffff;
}
.black-text {
  color: #020002;
}
.green-border {
  border: 1px solid #b0d137;
}
.ant-spin-text {
  color: red;
}
.shade-text {
  color: rgba(73, 73, 73, 0.5);
}

@font-face {
  font-family: "Glacial-Bold";
  src: url("./assets/font/GlacialIndifference-Bold.otf");
}

@font-face {
  font-family: "Glacial-Italic";
  src: url("./assets/font/GlacialIndifference-Italic.otf");
}

@font-face {
  font-family: "Glacial-Regular";
  src: url("./assets/font/GlacialIndifference-Regular.otf");
}

@font-face {
  font-family: "Gothic-Regular";
  src: url("./assets/font/gothicr.ttf");
}

@font-face {
  font-family: "Gothic-Bold";
  src: url("./assets/font/gothicb.ttf");
}

.Glacial-Bold {
  font-family: "Glacial-Bold" !important;
}

.Glacial-Italic {
  font-family: "Glacial-Italic" !important;
}

.Glacial-Regular {
  font-family: "Glacial-Regular" !important;
}

.Gothic-Regular {
  font-family: "Gothic-Regular" !important;
}

.Gothic-Bold {
  font-family: "Gothic-Bold" !important;
}

.margin-new {
  margin-bottom: -60px;
}

/* @media(max-width:1200px){
  .margin-new{
    margin-bottom: -220px;
  }
} */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media(max-width: 768px) and (min-width:420px) {
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
      0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 1200;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    outline: 0;
    right: 0;
    width: 350px !important;
  }
}


@media(min-width: 320px) and (max-width:500px){
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
      0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 1200;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    outline: 0;
    right: 0;
    width: 250px !important;
  }
  .css-1ab2xsx {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    z-index: 1200;
    position: fixed;
    top: 0px;
    outline: 0px;
    right: 0px;
    width: 250px !important;
}
}

@media(min-width: 500px) and (max-width:768px){
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
      0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 1200;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    outline: 0;
    right: 0;
    width: 300px !important;
  }
  .css-1ab2xsx {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    z-index: 1200;
    position: fixed;
    top: 0px;
    outline: 0px;
    right: 0px;
    width: 300px !important;
}
}


@media(min-width: 1024px) and (max-width:2560px){
  .css-1160xiw-MuiPaper-root-MuiDrawer-paper {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%),
      0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%);
    overflow-y: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
    z-index: 1200;
    -webkit-overflow-scrolling: touch;
    position: fixed;
    top: 0;
    outline: 0;
    right: 0;
    width: 500px !important;
  }
  .css-1ab2xsx {
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: rgb(0 0 0 / 20%) 0px 8px 10px -5px, rgb(0 0 0 / 14%) 0px 16px 24px 2px, rgb(0 0 0 / 12%) 0px 6px 30px 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 0 auto;
    z-index: 1200;
    position: fixed;
    top: 0px;
    outline: 0px;
    right: 0px;
    width: 500px !important;
}
}

.carousel .carousel-status {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  font-size: 10px;
  text-shadow: 1px 1px 1px rgb(0 0 0 / 90%);
  color: #fff;
  display: none;
}

.increment-decrement{
  width:30px;
  height:35px;
  text-align:center;
  background-color:#434343;
  color:white;
  border:1px solid black;
  cursor: pointer;
  padding-top: 2px;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background-color: grey !important ;
  color: #020002 !important;
}
.css-1ujsas3:hover {
  text-decoration: none;
  background-color: grey !important;
}
.slick-dots {
  position: absolute;
  bottom: -2px !important;
}
.slick-dots li button:before {
  opacity: .75;
  color: white !important;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: white !important;
}
.slick-dots li.slick-active button:after {
  opacity: .75;
  color: white !important;
}
.checkout-guest{
  text-align: center;
  padding-top: 10%;
  font-size: 30px;
  font-family: 'Gothic-Regular';
  font-weight: 700;
  border-right: 1px solid black;
}
.checkout-login{
  
  font-size: 30px;
  font-family: 'Gothic-Regular';
  font-weight: 700;
}
.checkout-grid{
  display: grid;
  grid-template-columns: 50% 60% ;
  grid-column-gap:20px;
  padding-bottom: 10%;
}
.close-image{
  width: 30px;
    height: 40px;
}
.modal-size{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background:white;
    width:80%;
    outline: 0 !important;
}
@media(min-width:320px) and (max-width:500px){
  .checkout-grid{
    display: grid;
    grid-template-columns: auto ;
    grid-column-gap:20px;
    padding-bottom: 10%;
  }
  .checkout-guest{
    text-align: center;
    padding-top: 10%;
    font-size: 20px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
    padding-bottom: 10%;
    border-bottom: 1px solid black;
  }
  .checkout-login{
    padding-top: 10%;
    font-size: 20px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
    text-align: center;
  }
  .login-fields{
    max-width: 90% !important;
    margin: 20px;
  }
  .not-registered{
    font-size: 20px !important;
  }
  .close-image{
    width: 30px !important;
      height: 40px !important;
  }
  
}
@media(min-width:500px) and (max-width:760px) {
    .modal-size{
    position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background:white;
      width:60%;
      outline: 0 !important;
  }
  .checkout-grid{
    display: grid;
    grid-template-columns: auto !important ;
    grid-column-gap:20px;
    padding-bottom: 10%;
  }
  .checkout-guest{
    text-align: center;
    padding-top: 10%;
    font-size: 20px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
    padding-bottom: 10%;
    border-bottom: 1px solid black;
  }
  .checkout-login{
    padding-top: 10%;
    font-size: 20px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
    text-align: center;
  }
  .login-fields{
    max-width: 90% !important;
    margin: 20px;
  }
  .not-registered{
    font-size: 20px !important;
  }
  .close-image{
    width: 30px !important;
      height: 40px !important;
  }
}
@media(min-width:768px) and (max-width:1023px){
  .modal-size{
    position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background:white;
      width:55% !important;
      outline: 0 !important;
  }
  .modal-size{
    position:absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background:white;
      width:60%;
      outline: 0 !important;
  }
  .checkout-grid{
    display: grid;
    grid-template-columns: auto !important ;
    grid-column-gap:20px;
    padding-bottom: 10%;
  }
  .checkout-guest{
    text-align: center;
    padding-top: 10%;
    font-size: 20px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
    padding-bottom: 10%;
    border-bottom: 1px solid black;
  }
  .checkout-login{
    padding-top: 10%;
    font-size: 20px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
    text-align: center;
  }
  .login-fields{
    max-width: 90% !important;
    margin: 20px;
  }
  .not-registered{
    font-size: 20px !important;
  }
  .close-image{
    width: 30px !important;
      height: 40px !important;
  }
}
@media(min-width:1024px) and (max-width:2560px){
  .checkout-grid{
    display: grid;
    grid-template-columns: 50% 60% ;
    grid-column-gap:20px;
    padding-bottom: 5%;
    padding-top: 5%;
  }
  .checkout-guest{
    text-align: center;
    padding-top: 10%;
    font-size: 30px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
    padding-bottom: 10%;
  }
  .checkout-login{
    font-size: 30px;
    font-family: 'Gothic-Regular';
    font-weight: 700;
   
  }
  .login-fields{
    max-width: 70% !important;
    margin: 20px;
  }
  .not-registered{
    font-size: 20px !important;
  }
  .close-image{
    width: 30px !important;
      height: 40px !important;
  }
  
}
.not-registered{
  font-size: 20px;
}
.login-fields{
  max-width: 50%;
}
.text-underline-1 {
  text-decoration: underline !important;
}

.empty-cart{
  display: flex;
  justify-content: center;
  padding-top: 20%;
  font-size: 20px;
  font-family: 'Gothic-Regular';
  font-weight: 700;
  color: gray;
}
.grid-view{
  display:grid !important;
  grid-template-columns:71% auto;
}
@media(max-width:768px){
  .grid-view{
    display:grid !important;
    grid-template-columns:auto;
  }
  .mobile-display{
    padding-left: 0px !important;
    padding-top: 10px !important;
  }
}
.css-l4o138-MuiPaper-root-MuiAlert-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: #454545 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 16px;
  color: white !important;
  margin-bottom: 16px;
}
.css-12loam1-MuiPaper-root-MuiAlert-root {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: #454545 !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 6px 16px;
  color: white !important;
  margin-bottom: 16px;
}
.css-1ytc1t2 {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: #454545 !important;
  display: flex;
  padding: 6px 16px;
  color: white !important;
  margin-bottom: 16px;
}
.css-14tzjyl {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  border-radius: 4px;
  background-color: #454545 !important;
  display: flex;
  padding: 6px 16px;
  color: white !important;
  margin-bottom: 16px;
}
.spec-image{
  height:80vh;
}
@media(max-width:480px){
  .spec-image{
    width:300px !important;
    height: auto;
  }
}
.border-bottom-new{
  border-bottom: 1px solid black !important;
  margin: 5%;
}
.trash-item{
    width: 20px;
    height: 20px;
    margin-left: 10%;
    margin-top: 5%;
}
.blok-total-value{
  display: inline-block;
  width: 100%;
}
.total-display{
  float: left;
  margin-left: 5%;
  font-weight: 700;
}
.value-display{
  float: right;
  margin-right: 5%;
}

.lock-display{
  width: 28px;
  height: 28px;
}
.css-l4o138-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
  color: white !important;
}
.css-12loam1-MuiPaper-root-MuiAlert-root .MuiAlert-icon {
  color: white !important;
}

.css-18lrjg1-MuiCircularProgress-root {
  display: inline-block;
  color: #000000 !important;
  -webkit-animation: animation-61bdi0 1.4s linear infinite;
  animation: animation-61bdi0 1.4s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}
.css-z01bqi {
  display: inline-block;
  color: #000000 !important;
  -webkit-animation: animation-61bdi0 1.4s linear infinite;
  animation: animation-61bdi0 1.4s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}
.css-1ytc1t2 .MuiAlert-icon {
  color: white !important;
}
.css-14tzjyl .MuiAlert-icon {
  color: white !important;
}
.background-image-data{
  background-image: url(./assets/images/1.png);
}

.jss1 {
  width: 100% !important;
}
.no-width{
  width:13rem;
}

@media(max-width:480px){
  /* .mobile-fonts{
    font-size: 7px !important;
  } */
}
.blok-spec{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  outline: 0 !important;
}
/* @media(max-width:480px){
  .blok-spec{
    height: 50vh;
  }
} */
